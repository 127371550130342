//
//
const authStatus = (state=null) => {
	return {
		type: "AUTHSTATUS",
		payload: state
	}
}


//
//
const authUpdate = (data) => {
	return {
		type: "AUTHUPDATE",
		payload: data
	}
}


//
//
const authLogout = (data) => {
	return {
		type: "AUTHLOGOUT",
		payload: null
	}
}


const auth = {
	authStatus,
	authUpdate,
	authLogout
};

//
//
export default auth
