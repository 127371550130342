import { inArray } from './helpers'

import * as Sentry from "@sentry/nextjs";


// 
// 
const _fetch = async(url, object, type) => {

    try{

        const res = await fetch(url, object)

        // 
        if(res.status===401 && window !== undefined){
            window.location.href = '/'
        }

        // 
        if(res.status !== 200){
            const json = await res.json()
            return json
        }
        
        // 
        if(type==='json'){

            const json = await res.json()

            if(json.error){
                Sentry.captureException(new Error(JSON.stringify(json.error)))
                // Flushing before returning is necessary if deploying to Vercel, see
                // https://vercel.com/docs/platform/limits#streaming-responses
                await Sentry.flush(2000)
                console.log('sentry error #2', json)
                return json
            }else{
                return json
            }
        }

        // 
        if(type==='blob'){
            return res.blob()
        }

    }catch (error){

        console.log('sentry error #1', error)
        Sentry.captureException(new Error(JSON.stringify(error)))
        // Flushing before returning is necessary if deploying to Vercel, see
        // https://vercel.com/docs/platform/limits#streaming-responses
        await Sentry.flush(2000)

        return {error:true, ...error}
    }
}


// 
// 
const _apiInit = async(props) => {

    let type        = props.type ? props.type : 'json'
    let baseUrl     = props.baseUrl ? props.baseUrl : process.env.NEXT_PUBLIC_API_URL
    let headers     = {'Content-Type':'application/json'}



    // 
    if(props.headers){
        headers = {...headers, ...props.headers}
    }

    // 
    let fetchObject = {
        headers: headers,
        method: props.method
    }
    
    // 
    if(props.credentials){
        fetchObject = {
            ...fetchObject,
            credentials: props.credentials
        }
    }
    
    // 
    if(props.withCredentials){
        fetchObject = {
            ...fetchObject,
            withCredentials: props.withCredentials
        }
    }

    // console.log(fetchObject)

    // 
    if(inArray(props.method, ['POST', 'PUT'])){
        fetchObject = {
            ...fetchObject,
            body: props.hasFormData ? props.data : JSON.stringify(props.data)
        }
    }
    //
    return await _fetch(baseUrl+props.path, fetchObject, type)
}


//
// 
const _apiGet = async(path, props={}) => {
    let _path = path
    if(props.params){
        const _params = new URLSearchParams(props.params).toString();
        _path = path+'?'+_params
    }
    return await _apiInit({method:'GET', ...props, path:_path})
}


//
// 
const _apiPost = async(path, props={}) => {
    let _path = path
    if(props.params){
        const _params = new URLSearchParams(props.params).toString();
        _path = path+'?'+_params
    }
    return await _apiInit({method:'POST', ...props, path:_path})
}


//
// 
// const _apiPut = async(path, {data={}, protectedRoute=false, headers={}, baseUrl=process.env.NEXT_PUBLIC_API_URL, type='json'}) => {
//     return _apiInit(protectedRoute, 'PUT', path, data, headers, baseUrl, type)
// }


//
// 
// const _apiDelete = async(path, {protectedRoute=false, headers={}, baseUrl=process.env.NEXT_PUBLIC_API_URL, type='json'}) => {
//     return _apiInit(protectedRoute, 'DELETE', path, {}, headers, baseUrl, type)
// }


// // 
// // 
// const _authorize = async() => {
    
//     const get = await _apiGet('/auth', {protectedRoute:true})
//     if(get.error){
//         await initializeStore().dispatch(_actions.auth.authUpdate(null))
//         return false
//     }
    
//     await initializeStore().dispatch(_actions.auth.authUpdate(get))
//     return get
// }


// // 
// // 
// const _logout = async(router) => {
//     await initializeStore().dispatch(_actions.auth.authLogout(null))
//     _toaster('Success','You have successfully logged out.', 'success')
//     router.push('/authentication/login')
// }


// 
// 
export {
    _apiGet,
    _apiPost,
    // _apiPut,
    // _apiDelete,
    // _authorize,
    // _logout
}